"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "ActionButtonView", {
  enumerable: true,
  get: function get() {
    return _ActionButtonView["default"];
  }
});
Object.defineProperty(exports, "AdBanner", {
  enumerable: true,
  get: function get() {
    return _BannerWrapper["default"];
  }
});
Object.defineProperty(exports, "AdBannerItem", {
  enumerable: true,
  get: function get() {
    return _Banner["default"];
  }
});
Object.defineProperty(exports, "AdBubble", {
  enumerable: true,
  get: function get() {
    return _MapBubble["default"];
  }
});
Object.defineProperty(exports, "AdFlag", {
  enumerable: true,
  get: function get() {
    return _MapFlag["default"];
  }
});
Object.defineProperty(exports, "AdFlyOver", {
  enumerable: true,
  get: function get() {
    return _FlyOver["default"];
  }
});
Object.defineProperty(exports, "AdMenu", {
  enumerable: true,
  get: function get() {
    return _Menu["default"];
  }
});
Object.defineProperty(exports, "BackgroundView", {
  enumerable: true,
  get: function get() {
    return _BackgroundView["default"];
  }
});
Object.defineProperty(exports, "Calendar", {
  enumerable: true,
  get: function get() {
    return _Calendar["default"];
  }
});
Object.defineProperty(exports, "Card", {
  enumerable: true,
  get: function get() {
    return _Card["default"];
  }
});
Object.defineProperty(exports, "ClockView", {
  enumerable: true,
  get: function get() {
    return _ClockView["default"];
  }
});
Object.defineProperty(exports, "DocumentPlaceholder", {
  enumerable: true,
  get: function get() {
    return _DocumentPlaceholder["default"];
  }
});
Object.defineProperty(exports, "Expandable", {
  enumerable: true,
  get: function get() {
    return _Expandable["default"];
  }
});
Object.defineProperty(exports, "FeedbackView", {
  enumerable: true,
  get: function get() {
    return _FeedbackView["default"];
  }
});
Object.defineProperty(exports, "FirstView", {
  enumerable: true,
  get: function get() {
    return _FirstView["default"];
  }
});
Object.defineProperty(exports, "ForgeView", {
  enumerable: true,
  get: function get() {
    return _ForgeView["default"];
  }
});
Object.defineProperty(exports, "FullscreenView", {
  enumerable: true,
  get: function get() {
    return _FullscreenView["default"];
  }
});
Object.defineProperty(exports, "GridView", {
  enumerable: true,
  get: function get() {
    return _GridView["default"];
  }
});
Object.defineProperty(exports, "HeaderView", {
  enumerable: true,
  get: function get() {
    return _HeaderView["default"];
  }
});
Object.defineProperty(exports, "HubMarker", {
  enumerable: true,
  get: function get() {
    return _HubMarker["default"];
  }
});
Object.defineProperty(exports, "HubMarkerNEW", {
  enumerable: true,
  get: function get() {
    return _HubMarkerNEW["default"];
  }
});
Object.defineProperty(exports, "ImagePlaceholder", {
  enumerable: true,
  get: function get() {
    return _ImagePlaceholder["default"];
  }
});
Object.defineProperty(exports, "ImageView", {
  enumerable: true,
  get: function get() {
    return _ImageView["default"];
  }
});
Object.defineProperty(exports, "LanguageView", {
  enumerable: true,
  get: function get() {
    return _LanguageView["default"];
  }
});
Object.defineProperty(exports, "LegendItem", {
  enumerable: true,
  get: function get() {
    return _LegendItem["default"];
  }
});
Object.defineProperty(exports, "LegendView", {
  enumerable: true,
  get: function get() {
    return _LegendView["default"];
  }
});
Object.defineProperty(exports, "LineBreak", {
  enumerable: true,
  get: function get() {
    return _LineBreak["default"];
  }
});
Object.defineProperty(exports, "LinkView", {
  enumerable: true,
  get: function get() {
    return _LinkView["default"];
  }
});
Object.defineProperty(exports, "ListItem", {
  enumerable: true,
  get: function get() {
    return _ListItem["default"];
  }
});
Object.defineProperty(exports, "ListView", {
  enumerable: true,
  get: function get() {
    return _ListView["default"];
  }
});
Object.defineProperty(exports, "MapArea", {
  enumerable: true,
  get: function get() {
    return _MapArea["default"];
  }
});
Object.defineProperty(exports, "MapBackground", {
  enumerable: true,
  get: function get() {
    return _MapBackground["default"];
  }
});
Object.defineProperty(exports, "MapHere", {
  enumerable: true,
  get: function get() {
    return _MapHere["default"];
  }
});
Object.defineProperty(exports, "MapLayer", {
  enumerable: true,
  get: function get() {
    return _MapLayer["default"];
  }
});
Object.defineProperty(exports, "MapLocation", {
  enumerable: true,
  get: function get() {
    return _MapLocation["default"];
  }
});
Object.defineProperty(exports, "MapPath", {
  enumerable: true,
  get: function get() {
    return _MapPath["default"];
  }
});
Object.defineProperty(exports, "MapView", {
  enumerable: true,
  get: function get() {
    return _MapView["default"];
  }
});
Object.defineProperty(exports, "Markdown", {
  enumerable: true,
  get: function get() {
    return _Markdown["default"];
  }
});
Object.defineProperty(exports, "MarkerView", {
  enumerable: true,
  get: function get() {
    return _MarkerView["default"];
  }
});
Object.defineProperty(exports, "MemoryView", {
  enumerable: true,
  get: function get() {
    return _memory["default"];
  }
});
Object.defineProperty(exports, "MenuHeader", {
  enumerable: true,
  get: function get() {
    return _MenuHeader["default"];
  }
});
Object.defineProperty(exports, "MenuItem", {
  enumerable: true,
  get: function get() {
    return _MenuItem["default"];
  }
});
Object.defineProperty(exports, "MissingFilePlaceholder", {
  enumerable: true,
  get: function get() {
    return _MissingFilePlaceholder["default"];
  }
});
Object.defineProperty(exports, "NoInfo", {
  enumerable: true,
  get: function get() {
    return _NoInfo["default"];
  }
});
Object.defineProperty(exports, "Passthrough", {
  enumerable: true,
  get: function get() {
    return _Passthrough["default"];
  }
});
Object.defineProperty(exports, "PathFinderView", {
  enumerable: true,
  get: function get() {
    return _PathFinderView["default"];
  }
});
Object.defineProperty(exports, "Pdf", {
  enumerable: true,
  get: function get() {
    return _Pdf["default"];
  }
});
Object.defineProperty(exports, "Picture", {
  enumerable: true,
  get: function get() {
    return _Picture["default"];
  }
});
Object.defineProperty(exports, "PopupView", {
  enumerable: true,
  get: function get() {
    return _PopupView["default"];
  }
});
Object.defineProperty(exports, "PrintFavorites", {
  enumerable: true,
  get: function get() {
    return _PrintFavorites["default"];
  }
});
Object.defineProperty(exports, "QuizLevel", {
  enumerable: true,
  get: function get() {
    return _QuizLevel["default"];
  }
});
Object.defineProperty(exports, "QuizLevelView", {
  enumerable: true,
  get: function get() {
    return _QuizLevelView["default"];
  }
});
Object.defineProperty(exports, "QuizView", {
  enumerable: true,
  get: function get() {
    return _QuizView["default"];
  }
});
Object.defineProperty(exports, "RichText", {
  enumerable: true,
  get: function get() {
    return _RichText["default"];
  }
});
Object.defineProperty(exports, "RouteView", {
  enumerable: true,
  get: function get() {
    return _RouteView["default"];
  }
});
Object.defineProperty(exports, "RoutingView", {
  enumerable: true,
  get: function get() {
    return _RoutingView["default"];
  }
});
Object.defineProperty(exports, "ScatterView", {
  enumerable: true,
  get: function get() {
    return _ScatterView["default"];
  }
});
Object.defineProperty(exports, "Screensaver", {
  enumerable: true,
  get: function get() {
    return _Screensaver["default"];
  }
});
Object.defineProperty(exports, "Search", {
  enumerable: true,
  get: function get() {
    return _Search["default"];
  }
});
Object.defineProperty(exports, "SearchFeedback", {
  enumerable: true,
  get: function get() {
    return _SearchFeedback["default"];
  }
});
Object.defineProperty(exports, "SearchView", {
  enumerable: true,
  get: function get() {
    return _SearchView["default"];
  }
});
Object.defineProperty(exports, "SendView", {
  enumerable: true,
  get: function get() {
    return _SendView["default"];
  }
});
Object.defineProperty(exports, "SidebarDetail", {
  enumerable: true,
  get: function get() {
    return _SidebarDetail["default"];
  }
});
Object.defineProperty(exports, "SidebarHeader", {
  enumerable: true,
  get: function get() {
    return _SidebarHeader["default"];
  }
});
Object.defineProperty(exports, "SidebarView", {
  enumerable: true,
  get: function get() {
    return _SidebarView["default"];
  }
});
Object.defineProperty(exports, "Simple", {
  enumerable: true,
  get: function get() {
    return _Simple["default"];
  }
});
Object.defineProperty(exports, "SimpleSidebarView", {
  enumerable: true,
  get: function get() {
    return _SimpleSidebarView["default"];
  }
});
Object.defineProperty(exports, "SlideShowView", {
  enumerable: true,
  get: function get() {
    return _SlideShowView["default"];
  }
});
Object.defineProperty(exports, "SwipeableDrawer", {
  enumerable: true,
  get: function get() {
    return _SwipeableDrawer["default"];
  }
});
Object.defineProperty(exports, "TimeTable", {
  enumerable: true,
  get: function get() {
    return _TimeTable["default"];
  }
});
Object.defineProperty(exports, "TimeTableDay", {
  enumerable: true,
  get: function get() {
    return _TimeTableDay["default"];
  }
});
Object.defineProperty(exports, "TimeTableView", {
  enumerable: true,
  get: function get() {
    return _TimeTableView["default"];
  }
});
Object.defineProperty(exports, "ToggleView", {
  enumerable: true,
  get: function get() {
    return _ToggleView["default"];
  }
});
Object.defineProperty(exports, "UnknownFilePlaceholder", {
  enumerable: true,
  get: function get() {
    return _UnknownFilePlaceholder["default"];
  }
});
Object.defineProperty(exports, "UpButtonView", {
  enumerable: true,
  get: function get() {
    return _UpButtonView["default"];
  }
});
Object.defineProperty(exports, "Video", {
  enumerable: true,
  get: function get() {
    return _Video["default"];
  }
});
Object.defineProperty(exports, "VideoPlaceholder", {
  enumerable: true,
  get: function get() {
    return _VideoPlaceholder["default"];
  }
});
Object.defineProperty(exports, "ViewManager", {
  enumerable: true,
  get: function get() {
    return _ViewManager["default"];
  }
});
Object.defineProperty(exports, "Web", {
  enumerable: true,
  get: function get() {
    return _Web["default"];
  }
});
Object.defineProperty(exports, "WebsitePlaceholder", {
  enumerable: true,
  get: function get() {
    return _WebsitePlaceholder["default"];
  }
});
exports["default"] = void 0;

var _ViewManager = _interopRequireDefault(require("./ViewManager"));

var _Banner = _interopRequireDefault(require("./ads/Banner"));

var _BannerWrapper = _interopRequireDefault(require("./ads/BannerWrapper"));

var _FlyOver = _interopRequireDefault(require("./ads/FlyOver"));

var _Menu = _interopRequireDefault(require("./ads/Menu"));

var _MapBubble = _interopRequireDefault(require("./ads/MapBubble"));

var _MapFlag = _interopRequireDefault(require("./ads/MapFlag"));

var _Card = _interopRequireDefault(require("./generic/Card"));

var _ListItem = _interopRequireDefault(require("./generic/ListItem"));

var _LegendItem = _interopRequireDefault(require("./generic/LegendItem"));

var _Pdf = _interopRequireDefault(require("./generic/Pdf"));

var _Picture = _interopRequireDefault(require("./generic/Picture"));

var _Simple = _interopRequireDefault(require("./generic/Simple"));

var _Video = _interopRequireDefault(require("./generic/Video"));

var _NoInfo = _interopRequireDefault(require("./generic/NoInfo"));

var _Expandable = _interopRequireDefault(require("./generic/Expandable"));

var _Web = _interopRequireDefault(require("./generic/Web"));

var _Calendar = _interopRequireDefault(require("./generic/Calendar"));

var _Passthrough = _interopRequireDefault(require("./generic/Passthrough"));

var _Markdown = _interopRequireDefault(require("./generic/Markdown"));

var _RichText = _interopRequireDefault(require("./generic/RichText"));

var _PrintFavorites = _interopRequireDefault(require("./generic/sidebar/PrintFavorites"));

var _SearchFeedback = _interopRequireDefault(require("./generic/sidebar/SearchFeedback"));

var _SidebarDetail = _interopRequireDefault(require("./generic/sidebar/SidebarDetail"));

var _SidebarHeader = _interopRequireDefault(require("./generic/sidebar/SidebarHeader"));

var _MapBackground = _interopRequireDefault(require("./map/MapBackground"));

var _MapHere = _interopRequireDefault(require("./map/MapHere"));

var _MapLayer = _interopRequireDefault(require("./map/MapLayer"));

var _MapArea = _interopRequireDefault(require("./map/MapArea"));

var _MapLocation = _interopRequireDefault(require("./map/MapLocation"));

var _MapPath = _interopRequireDefault(require("./map/MapPath"));

var _TimeTable = _interopRequireDefault(require("./timetable/TimeTable"));

var _TimeTableDay = _interopRequireDefault(require("./timetable/TimeTableDay"));

var _Screensaver = _interopRequireDefault(require("./slides/Screensaver"));

var _SlideShowView = _interopRequireDefault(require("./slides/SlideShowView"));

var _MenuHeader = _interopRequireDefault(require("./slides/MenuHeader"));

var _MenuItem = _interopRequireDefault(require("./slides/MenuItem"));

var _LineBreak = _interopRequireDefault(require("./slides/LineBreak"));

var _HubMarker = _interopRequireDefault(require("./hub/HubMarker"));

var _HubMarkerNEW = _interopRequireDefault(require("./hub/MarkerView/HubMarkerNEW"));

var _ForgeView = _interopRequireDefault(require("./hub/ForgeView"));

var _Search = _interopRequireDefault(require("./util/Search"));

var _SwipeableDrawer = _interopRequireDefault(require("./util/SwipeableDrawer"));

var _ListView = _interopRequireDefault(require("./generic/ListView"));

var _ClockView = _interopRequireDefault(require("./generic/ClockView"));

var _LanguageView = _interopRequireDefault(require("./generic/LanguageView"));

var _LegendView = _interopRequireDefault(require("./generic/LegendView"));

var _FeedbackView = _interopRequireDefault(require("./generic/FeedbackView"));

var _FullscreenView = _interopRequireDefault(require("./generic/FullscreenView"));

var _PopupView = _interopRequireDefault(require("./generic/PopupView"));

var _ScatterView = _interopRequireDefault(require("./generic/ScatterView"));

var _SidebarView = _interopRequireDefault(require("./generic/sidebar/SidebarView"));

var _SimpleSidebarView = _interopRequireDefault(require("./generic/sidebar/SimpleSidebarView"));

var _BackgroundView = _interopRequireDefault(require("./generic/BackgroundView"));

var _SendView = _interopRequireDefault(require("./generic/SendView"));

var _FirstView = _interopRequireDefault(require("./generic/FirstView"));

var _ActionButtonView = _interopRequireDefault(require("./generic/ActionButtonView"));

var _RoutingView = _interopRequireDefault(require("./generic/routing/RoutingView"));

var _RouteView = _interopRequireDefault(require("./generic/routing/RouteView"));

var _UpButtonView = _interopRequireDefault(require("./generic/routing/UpButtonView"));

var _LinkView = _interopRequireDefault(require("./generic/routing/LinkView"));

var _MapView = _interopRequireDefault(require("./map/MapView"));

var _PathFinderView = _interopRequireDefault(require("./map/PathFinderView"));

var _TimeTableView = _interopRequireDefault(require("./timetable/TimeTableView"));

var _HeaderView = _interopRequireDefault(require("./slides/HeaderView"));

var _GridView = _interopRequireDefault(require("./slides/GridView"));

var _SearchView = _interopRequireDefault(require("./slides/SearchView"));

var _ToggleView = _interopRequireDefault(require("./slides/ToggleView"));

var _ImageView = _interopRequireDefault(require("./hub/ImageView"));

var _MarkerView = _interopRequireDefault(require("./hub/MarkerView/MarkerView"));

var _QuizView = _interopRequireDefault(require("./games/quiz/QuizView"));

var _QuizLevelView = _interopRequireDefault(require("./games/quiz/QuizLevelView"));

var _QuizLevel = _interopRequireDefault(require("./games/quiz/QuizLevel"));

var _memory = _interopRequireDefault(require("./games/memory"));

var _MissingFilePlaceholder = _interopRequireDefault(require("./placeholders/MissingFilePlaceholder"));

var _UnknownFilePlaceholder = _interopRequireDefault(require("./placeholders/UnknownFilePlaceholder"));

var _ImagePlaceholder = _interopRequireDefault(require("./placeholders/ImagePlaceholder"));

var _VideoPlaceholder = _interopRequireDefault(require("./placeholders/VideoPlaceholder"));

var _DocumentPlaceholder = _interopRequireDefault(require("./placeholders/DocumentPlaceholder"));

var _WebsitePlaceholder = _interopRequireDefault(require("./placeholders/WebsitePlaceholder"));

function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { "default": obj }; }

// view manager (must be first import, has css baseline)
// ads components
// generic components
// generic sidebar components
// map components
// timetable components
// menu/slide components
// hub components
// util components
// generic views
// routing views
// map views
// timetable views
// menu/slide views
// hub views
// quiz
// placeholders
// allows importing single components, e.g. import { Card } from 'components/cluud-components'
// allows importing all components, e.g. import components from 'components/cluud-components'
var _default = {
  /*
  COMPONENTS
  */
  // generic
  Card: _Card["default"],
  ListItem: _ListItem["default"],
  LegendItem: _LegendItem["default"],
  Pdf: _Pdf["default"],
  Picture: _Picture["default"],
  Simple: _Simple["default"],
  Video: _Video["default"],
  Expandable: _Expandable["default"],
  Web: _Web["default"],
  Calendar: _Calendar["default"],
  Passthrough: _Passthrough["default"],
  Markdown: _Markdown["default"],
  RichText: _RichText["default"],
  // generic sidebar
  NoInfo: _NoInfo["default"],
  PrintFavorites: _PrintFavorites["default"],
  SearchFeedback: _SearchFeedback["default"],
  SidebarDetail: _SidebarDetail["default"],
  SidebarHeader: _SidebarHeader["default"],
  // map
  MapBackground: _MapBackground["default"],
  MapHere: _MapHere["default"],
  MapLayer: _MapLayer["default"],
  // map ads
  AdFlag: _MapFlag["default"],
  AdMenu: _Menu["default"],
  AdBannerItem: _Banner["default"],
  AdBanner: _BannerWrapper["default"],
  AdFlyOver: _FlyOver["default"],
  AdBubble: _MapBubble["default"],
  // timetable
  TimeTable: _TimeTable["default"],
  TimeTableDay: _TimeTableDay["default"],
  // menu/slide
  Screensaver: _Screensaver["default"],
  SlideShowView: _SlideShowView["default"],
  MenuHeader: _MenuHeader["default"],
  MenuItem: _MenuItem["default"],
  LineBreak: _LineBreak["default"],
  // hub
  HubMarker: _HubMarker["default"],
  HubMarkerNEW: _HubMarkerNEW["default"],
  // quiz
  QuizLevel: _QuizLevel["default"],
  // util
  Search: _Search["default"],
  SwipeableDrawer: _SwipeableDrawer["default"],
  // placeholders
  MissingFilePlaceholder: _MissingFilePlaceholder["default"],
  UnknownFilePlaceholder: _UnknownFilePlaceholder["default"],
  ImagePlaceholder: _ImagePlaceholder["default"],
  VideoPlaceholder: _VideoPlaceholder["default"],
  DocumentPlaceholder: _DocumentPlaceholder["default"],
  WebsitePlaceholder: _WebsitePlaceholder["default"],

  /*
  VIEW MANAGER
  */
  ViewManager: _ViewManager["default"],

  /*
  VIEWS
  */
  // generic
  ListView: _ListView["default"],
  ClockView: _ClockView["default"],
  LanguageView: _LanguageView["default"],
  LegendView: _LegendView["default"],
  FeedbackView: _FeedbackView["default"],
  ScatterView: _ScatterView["default"],
  SidebarView: _SidebarView["default"],
  SimpleSidebarView: _SimpleSidebarView["default"],
  FullscreenView: _FullscreenView["default"],
  PopupView: _PopupView["default"],
  BackgroundView: _BackgroundView["default"],
  SendView: _SendView["default"],
  FirstView: _FirstView["default"],
  ActionButtonView: _ActionButtonView["default"],
  // routing
  RoutingView: _RoutingView["default"],
  RouteView: _RouteView["default"],
  UpButtonView: _UpButtonView["default"],
  LinkView: _LinkView["default"],
  // map
  MapView: _MapView["default"],
  MapArea: _MapArea["default"],
  MapLocation: _MapLocation["default"],
  PathFinderView: _PathFinderView["default"],
  MapPath: _MapPath["default"],
  // timetable
  TimeTableView: _TimeTableView["default"],
  // menu/slide
  GridView: _GridView["default"],
  HeaderView: _HeaderView["default"],
  SearchView: _SearchView["default"],
  ToggleView: _ToggleView["default"],
  // hub
  ImageView: _ImageView["default"],
  ForgeView: _ForgeView["default"],
  MarkerView: _MarkerView["default"],
  // quiz
  QuizView: _QuizView["default"],
  QuizLevelView: _QuizLevelView["default"],
  MemoryView: _memory["default"]
};
exports["default"] = _default;