"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports["default"] = void 0;
var json = {
  feedback: {
    question: {
      wayfinder: 'Ist diese Karte hilfreich?',
      presentation: 'Ist diese Präsentation hilfreich?',
      "default": 'Ist diese Software hilfreich?'
    },
    thanks: 'Danke für Ihr Feedback!'
  },
  search: {
    title: 'Suche',
    hintText: 'Suchen',
    hintTextLocal: '%% suchen',
    noResults: 'Ihre Suche lieferte keine Ergebnisse. Bitte probieren Sie einen anderen Suchbegriff.',
    noInput: 'Nutzen Sie das Suchfeld oben. Die Resultate erscheinen hier.',
    global: 'Alle Bereiche durchsuchen'
  },
  common: {
    name: 'Name',
    time: 'Zeit',
    noInfo: 'Keine Informationen vorhanden',
    send: 'Senden'
  },
  pdf: {
    loading: 'Dokument wird geladen...'
  },
  web: {
    loading: 'Inhalt wird geladen...'
  },
  video: {
    unsupported: 'Video Wiedergabe nicht unterstützt'
  },
  timetable: {
    noEvents: 'Hier finden aktuell keine Aktivitäten statt'
  }
};
var _default = json;
exports["default"] = _default;